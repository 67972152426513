import * as focusTrap from 'focus-trap';
import {MARKUP} from './helpers';

class ZipData {
    public zipCode: string;
    public clubState: string;
    public clubCode: string;
    public fullState: string;
    public clubName: string;

    constructor(zip: string, code: string, club: string, state: string, name: string) {
        this.zipCode = zip;
        this.clubState = club;
        this.clubCode = code;
        this.fullState = state;
        this.clubName = name;
    }
}

// @ts-ignore
//window.zm_show = "";
// @ts-ignore
//window.zm_entry = "";
// @ts-ignore
//window.zm_destination = "";

const ZIP_API = "https://api.ace.aaa.com/zip-router/";
const ZIP_CRAWLERS = ["googlebot", "backrub", "msnbot", "yahoo", "lycos", "mercator", "keynote", "slurp"];
const CLUB_STATES = {
    "al": {fullState: "Alabama", club: "alabama", zipCode: "35004", clubState: "AL", clubCode: "1"},
    "alabama": {fullState: "Alabama", club: "alabama", zipCode: "35004", clubState: "AL", clubCode: "1"},
    "autoclubmo": {fullState: "Mississippi", club: "autoclubmo", zipCode: "38601", clubState: "ACMO", clubCode: "65"},
    "acmo": {fullState: "Mississippi", club: "autoclubmo", zipCode: "38601", clubState: "ACMO", clubCode: "65"},
    "ar": {fullState: "Arkansas", club: "autoclubmo", zipCode: "71601", clubState: "ACMO", clubCode: "65"},
    "il": {fullState: "Illinois", club: "autoclubmo", zipCode: "62001", clubState: "ACMO", clubCode: "65"},
    "in": {fullState: "Indiana", club: "autoclubmo", zipCode: "47116", clubState: "ACMO", clubCode: "65"},
    "ks": {fullState: "Kansas", club: "autoclubmo", zipCode: "35004", clubState: "ACMO", clubCode: "65"},
    "la": {fullState: "Louisiana", club: "autoclubmo", zipCode: "70001", clubState: "ACMO", clubCode: "65"},
    "mo": {fullState: "Missouri", club: "autoclubmo", zipCode: "63001", clubState: "ACMO", clubCode: "65"},
    "ms": {fullState: "Mississippi", club: "autoclubmo", zipCode: "38601", clubState: "ACMO", clubCode: "65"},
    "ca": {fullState: "California", club: "calif", zipCode: "92626", clubState: "CA", clubCode: "4"},
    "calif": {fullState: "California", club: "calif", zipCode: "92626", clubState: "CA", clubCode: "4"},
    "eastcentral": {fullState: "Ohio", club: "eastcentral", zipCode: "43101", clubState: "EC", clubCode: "215"},
    "ec": {fullState: "Ohio", club: "eastcentral", zipCode: "43101", clubState: "EC", clubCode: "215"},
    "ky": {fullState: "Kentucky", club: "eastcentral", zipCode: "40003", clubState: "EC", clubCode: "215"},
    "ny": {fullState: "New York", club: "eastcentral", zipCode: "14571", clubState: "EC", clubCode: "215"},
    "oh": {fullState: "Ohio", club: "eastcentral", zipCode: "43101", clubState: "EC", clubCode: "215"},
    "pa": {fullState: "Pennsylvania", club: "eastcentral", zipCode: "17017", clubState: "EC", clubCode: "215"},
    "wv": {fullState: "West Virginia", club: "eastcentral", zipCode: "24851", clubState: "EC", clubCode: "215"},
    "hawaii": {fullState: "Hawaii", club: "hawaii", zipCode: "96701", clubState: "HI", clubCode: "018"},
    "hi": {fullState: "Hawaii", club: "hawaii", zipCode: "96701", clubState: "HI", clubCode: "018"},
    "newmexico": {fullState: "New Mexico", club: "newmexico", zipCode: "87001", clubState: "NM", clubCode: "601"},
    "nm": {fullState: "New Mexico", club: "newmexico", zipCode: "87001", clubState: "NM", clubCode: "601"},
    "northernnewengland": {fullState: "Maine", club: "northernnewengland", zipCode: "03031", clubState: "NNE", clubCode: "36"},
    "nne": {fullState: "Maine", club: "northernnewengland", zipCode: "03031", clubState: "NNE", clubCode: "36"},
    "me": {fullState: "Maine", club: "northernnewengland", zipCode: "04112", clubState: "NNE", clubCode: "36"},
    "nh": {fullState: "New Hampshire", club: "northernnewengland", zipCode: "03031", clubState: "NNE", clubCode: "36"},
    "vt": {fullState: "Vermont", club: "northernnewengland", zipCode: "05001", clubState: "NNE", clubCode: "36"},
    "texas": {fullState: "Texas", club: "texas", zipCode: "73344", clubState: "TX", clubCode: "252"},
    "tx": {fullState: "Texas", club: "texas", zipCode: "73344", clubState: "TX", clubCode: "252"},
    "va": {fullState: "Virginia", club: "tidewater", zipCode: "22432", clubState: "TW", clubCode: "258"}
};
// @ts-ignore
const STATES_MAP = new Map(Object.entries(CLUB_STATES));
const USER_AGENT = window.navigator.userAgent;
const ON_MOBILE = USER_AGENT.match(/iPhone|iPod/i) !== null || (USER_AGENT.match(/iPad/) && (window.innerWidth < 1024)) || (USER_AGENT.match(/Android/));
const QUERY_STRINGS = new URLSearchParams(window.location.search);
const ALLOWED_BYPASSES = ["dz", "pmed", "mobileapp", "ace", "zip"];
const STRONG_BAD = new RegExp(/([<>{}][script]*)/gi);
const STRONG_BAD_DIGI_MAR = new RegExp(/([<>][script]*)/gi);
let bypassZip = "";
let zipDigitInput, zipFormButton, zipCodeClear, zipForm, nationalZipCookie, inAEM;
let deviceType = "PC";
let firstActiveElement;
let trapHouse;

function initZipRouter():void {
    ////kill if being crawled
    if (crawlerCheck()) {
        return;
    }
    /////in AEM author or localhost, don't show
    if (window.location.host.match(/author/) !== null || window.location.host.match(/localhost/) !== null) {
        return;
    }

    firstActiveElement = document.activeElement;

    ///check if AEM or APPS version
    inAEM = (window.location.host.split('.')[0] === "www" || window.location.host.split('.')[0].indexOf("aem") > -1);

    // handle Pay without login Rhode Island.  If returning RI, don't route
    if (cookieMonster("ripwol") !== null) {
        zipUtag("false", "ripwol", window.location.href);
        return;
    }

    ////check for allowed bypass zip query strings.  Stop if bypassing
    if(zipBypassHandler()){
        return;
    }

    nationalZipCookie = cookieMonster("zipcode");
    ///check if route cleared or clear requested
    if (nationalZipCookie === null) {
        launchZipModal();
        return;
    } else {
        /////zip code is present but came in from National router, need to get full details
        const nationalZipVal = nationalZipCookie.split("|")[0];
        let clubFullState = cookieMonster("aaa-state");
        if (clubFullState === null) {
            // @ts-ignore
            window.zm_entry = "nat_zip"
            handleZipInput(nationalZipVal, true, true);
            return;
        }
        ////AEM rule for club object to exist
        if (inAEM) {
            // @ts-ignore
            if (currentClub === null || currentClub === undefined) {
                launchZipModal();
                return;
            }
            const nationalCodeCheck = nationalZipCookie.split("|")[2];
            // @ts-ignore
            if (parseInt(nationalCodeCheck) !== parseInt(currentClub.clubCode)) {
                launchZipModal();
                return;
            }
        }
    }
    zipUtag("false", "cookie", window.location.href);
}

export function zipBypassHandler():boolean{
    let zipBypassed:boolean = false;
    ALLOWED_BYPASSES.forEach( (bypassKey:string, bypassIndex:number) => {
        let bypassValue:string = QUERY_STRINGS.get(bypassKey);
        if(bypassValue !== null){
            ///handle RI Pay without login
            if(bypassValue === "ripwol"){
                document.cookie = "ripwol=true; domain=.aaa.com; secure; path=/";
                zipBypassed = true;
            }else if(!zipBypassed && ALLOWED_BYPASSES[bypassIndex] === "dz" || ALLOWED_BYPASSES[bypassIndex] === "pmed"){
                if(digitalMarketingHandler(ALLOWED_BYPASSES[bypassIndex], bypassValue)){
                    zipBypassed = true;
                }
            }else if(!zipBypassed && ALLOWED_BYPASSES[bypassIndex].toLowerCase() === "mobileapp"){
                if(mobileAppBypass()){
                    zipBypassed = true;
                }
            }else if(!zipBypassed && ALLOWED_BYPASSES[bypassIndex].toLowerCase() === "ace"){
                if(aceHandler(bypassValue)){
                    zipBypassed = true;
                }
            }else if(!zipBypassed && ALLOWED_BYPASSES[bypassIndex].toLowerCase() === "zip"){
                if(providedZipHandler(bypassValue)){
                    zipBypassed = true;
                }
            }
        }
    });


    if(zipBypassed){
        //@ts-ignore
        window.zm_entry = "bypass";
        ///special condition for ripwol
        if(bypassZip !== ""){
            handleZipInput(bypassZip, false, true);
        }

    }

    return zipBypassed;
}

export function mobileAppBypass():boolean{
    ///check if cookie exists
    let mobileAppZipCookie:string = cookieMonster("zipcode");
    if(mobileAppZipCookie !== null){
        bypassZip = mobileAppZipCookie.split("|")[0];
        return true;
    }else{
        ///no cookie, look for zip in query 1
        let mobileAppZip:string = QUERY_STRINGS.get("zip");
        if(mobileAppZip !== null && STRONG_BAD.test(mobileAppZip) === false){
            bypassZip = mobileAppZip;
            return true;
        }
    }
    return false;
}

export function digitalMarketingHandler(bypassKey:string, bypassValue:string):boolean{
    ///sanitize
    if(STRONG_BAD_DIGI_MAR.test(bypassValue) !== false){
        return false;
    }

    ////DZ has priority over PMED, if present, take value
    if(bypassKey === "dz"){
        if (!isNaN(parseInt(bypassValue))) {
            bypassZip = bypassValue;
            return true;
        }
    }else if(bypassKey === "pmed" && bypassZip === ""){
        /////make sure bypassZip is blank since DZ takes priority over PMED
        ////find state in query string
        let targetClub = pmedValidation(bypassValue);
        ////if club found from pmed, bypass router and populate
        if (targetClub !== undefined) {
            bypassZip = targetClub.zipCode;
            return true;
        }
    }

    return false;
}

export function aceHandler(clubNameBypass:string):boolean{
    ///has value and plays nice
    if (clubNameBypass !== null && STRONG_BAD.test(clubNameBypass) === false) {
        let aceBypassClub = STATES_MAP.get(clubNameBypass.toLowerCase());
        bypassZip = aceBypassClub.zipCode;
        return true;
    }

    return false;
}

export function providedZipHandler(zipCode:string):boolean{
    if (!isNaN(parseInt(zipCode))) {
        bypassZip = zipCode;
        return true;
    }else if(zipCode === "stop"){
        clearZipCookie();
    }

    return false;
}

/**
 *
 *  Displays zip code router modal
 *
 */
export function launchZipModal():void {
    setTimeout(() => {
        let domoRoboto = document.createElement("link");
        domoRoboto.href = "https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap";
        domoRoboto.rel = "stylesheet";
        domoRoboto.type = "text/css";
        document.getElementsByTagName("head")[0].appendChild(domoRoboto);
        document.body.setAttribute('aria-live', 'assertive');
        zipUtag("true", null, null);
        ////add markup and style
        const zipModalMarkup = MARKUP.HTML;
        const zipModalCss = MARKUP.CSS;
        const zipModalStyle = document.createElement('style');
        zipModalStyle.textContent = zipModalCss;
        document.head.append(zipModalStyle);
        document.body.insertAdjacentHTML("beforeend", zipModalMarkup);
        ///set welcome text, footer, and update logo for CA
        document.querySelector("body").classList.add("zip-modal-open");
        document.getElementById("zipRouterModal").style.display = "block";
        document.addEventListener('keydown', keydownHandler);
        ////define elements
        zipFormButton = document.querySelector(".zip-form-button");
        zipDigitInput = document.querySelector(".zip-digit-input");
        zipCodeClear = document.getElementById("zipCodeClear");
        zipForm = document.getElementById("zipForm");

        zipDigitInput.addEventListener("keyup", e => {
            ///android handler
            if (e.isComposing || e.code.toString() === "229") {
                return;
            }

            if (e.key === "Enter" && e.target.id === "zipCodeClear") {
                e.preventDefault();
                e.stopPropagation();
                clearZipInput();
            }

            if (e.key === "Enter") {
                zipValidationHandler();
            }

            if (e.key.toLowerCase() === "enter" && zipDigitInput.value.length === 5) {
                e.preventDefault();
                handleZipInput(zipDigitInput.value);
            }
        });

        ////handle submit button on desktop
        zipFormButton.addEventListener("click", () => {
            if (zipDigitInput.value.length === 5) {
                handleZipInput(zipDigitInput.value);
            }
        });

        zipFormButton.addEventListener("keydown", (e) => {
            if (e.key === "Enter") {
                zipValidationHandler();
            }

            if (e.key === 'Enter' && zipDigitInput.value.length === 5) {
                e.preventDefault();
                e.stopImmediatePropagation();
                handleZipInput(zipDigitInput.value);
            }
        });

        zipDigitInput.addEventListener('keypress', (e) => {
            if (e.which !== 8 && e.which !== 0 && e.which < 48 || e.which > 57) {
                e.preventDefault();
            }
        });

        /////input handler for enable/disable submit button
        zipDigitInput.addEventListener("input", () => {
            if (zipDigitInput.value > 0) {
                document.getElementById('zipCodeClear').style.visibility = "visible";
            } else {
                document.getElementById('zipCodeClear').style.visibility = "hidden";
            }
            ///handle if over 5 characters
            if (zipDigitInput.value.length > 5) {
                zipDigitInput.value = zipDigitInput.value.slice(0, 5);
                return;
            }

            ////check if all five digits have been entered
            if (zipDigitInput.value.length === 5) {
                zipValidationHandler();
                zipFormButton.classList.remove("btn-disabled");
                zipFormButton.classList.add("btn-color-blue");
            } else {
                ///if user deletes a digit need to disable submit button
                if (zipFormButton.className.match("btn-color-blue") !== null) {
                    zipFormButton.classList.remove("btn-color-blue");
                    zipFormButton.classList.add("btn-disabled");
                }
            }
        });

        zipDigitInput.addEventListener('focusout', zipValidationHandler);

        zipCodeClear.addEventListener("click", () => {
            clearZipInput();
        });

        zipCodeClear.addEventListener('keydown', (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
                clearZipInput();
            }
        });

        zipDigitInput.addEventListener('focusout', zipValidationHandler);

        let ft = focusTrap.createFocusTrap(document.getElementById("zipRouterModal").getElementsByClassName('zip-modal-dialog')[0] as any, {
            onActivate: function () {
                document.querySelector(".zip-modal-content").className = "zip-modal-content trap is-active";
            },
            onDeactivate: function () {
                document.querySelector(".zip-modal-content").className = "zip-modal-content trap";
            },
            preventScroll: true
        });
        ft.activate();
        trapHouse = ft;

        ////update device type for mobile
        if (getMobileOS() === "iOS") {
            deviceType = (USER_AGENT.match(/iPad/) === null) ? "SP" : "TB";
        } else if (getMobileOS() === "Android") {
            deviceType = (window.innerWidth < 1024) ? "SP" : "TB";
        }
    }, 500);

}

export function clearZipInput():void {
    zipDigitInput.value = null;
    zipFormButton.classList.remove("btn-color-blue");
    zipFormButton.classList.add("btn-disabled");
    document.getElementById('zipCodeClear').style.visibility = "hidden";
    zipDigitInput.focus();
}

function keydownHandler(e):void {
    if (e.key === "Tab" && document.activeElement === zipDigitInput && zipDigitInput.value.length < 5) {
        zipValidationHandler();
        zipDigitInput.focus();
        e.preventDefault();
    }
}

function zipValidationHandler():void {
    if (zipDigitInput.value.length < 5) {
        document.getElementById("zipFormInputInvalid").setAttribute("aria-live", "assertive");
        document.getElementById("zipFormInputInvalid").style.display = "flex";
        document.getElementById('zipCode').classList.add("zip-input-invalid");
        zipDigitInput.setAttribute("aria-invalid", "true");
    } else {
        document.getElementById("zipFormInputInvalid").setAttribute("aria-live", "off");
        document.getElementById("zipFormInputInvalid").style.display = "none";
        document.getElementById("zipCode").classList.remove("zip-input-invalid");
        zipDigitInput.setAttribute("aria-invalid", "false");
    }
}

function removeZipModal() {
    document.querySelector("body").classList.remove("zip-modal-open");
    document.getElementById("zipRouterModal").style.display = "none";
    document.body.setAttribute('aria-live', 'off');
    ////catch edge case for uat apps
    try{
        trapHouse.deactivate();
    }catch(error){
        ///catching edge case preventing zip router completing
    }

    // @ts-ignore
    document.activeElement.blur();
    document.querySelector("body").setAttribute("tabindex", "-1")
    document.querySelector("body").focus();

    setTimeout(() => {
        document.querySelector("body").removeAttribute('tabindex');
    });
}

/**
 *
 *  Process user submitted zip code.  Will update cookie if member is within ACE and send to correct club or it will
 *  route member to National router URL with zip code appended.
 *
 * @param zipCode - user submitted zip code
 * @param zipModalHidden - handler for bypass logic
 * @param nationalOption - option to only update missing values when routed by national.
 */
export function handleZipInput(zipCode, nationalOption = false, zipModalHidden = false):void {
    let zipGateURL:string = `https://zipgate.aaa.com/?area=${redirectArea()}&zipcode=${zipCode}`;
    ///call API
    fetch(ZIP_API + zipCode)
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            if (response.clubs !== undefined) {
                try {
                    let zipObject = new ZipData(response.clubs[0].zipCode, response.clubs[0].clubCode, "", "", "");
                    let updateType = "full";
                    ////check if ACE or non-ACE data is returned.  Non-ACE will not include full state
                    if (response.clubs[0].fullState !== undefined) {
                        zipObject.fullState = response.clubs[0].fullState;
                        zipObject.clubState = response.clubs[0].clubState;
                        zipObject.clubName = STATES_MAP.get(response.clubs[0].clubState.toLowerCase()).club;
                        if (!zipModalHidden) {
                            removeZipModal();
                        }

                        ////check if exiting cookie and if data is the same
                        let currentZip = cookieMonster("zipcode");
                        if (currentZip !== null) {
                            ///check to see if zip is the same
                            if (currentZip.split("|")[0] === zipObject.zipCode) {
                                ///zip code is the same, don't need to do anything
                                const zipCodeSameEvent = new CustomEvent("zipCodeSameEvent", {detail: {zipCode: zipObject.zipCode}});
                                ///need to ensure our state level cookie is written.  If it's missing, need to write it.
                                let clubState = cookieMonster("aaa-state");
                                ////check to see if aaa-state cookie exists
                                if (clubState !== null) {
                                    ////don't do anything if zip cookie is the same
                                    if (clubState === zipObject.fullState) {
                                        document.dispatchEvent(zipCodeSameEvent);
                                        return;
                                    }
                                }
                                // update only the aaa-state cookie
                                const expDate = new Date();
                                ///30 day CA cookie life
                                const cookieLength = (zipObject.clubState === "CA") ? 30 : 365;
                                expDate.setDate(expDate.getDate() + cookieLength);
                                document.cookie = "aaa-state=" + zipObject.fullState + ";expires=" + expDate.toUTCString() + "; domain=.aaa.com; secure; path=/";
                                document.dispatchEvent(zipCodeSameEvent);
                                return;
                            } else if (currentZip.split("|")[2] === zipObject.clubCode && currentZip.split("|")[0] !== zipObject.zipCode) {
                                ////zip is different, but club code is the same, update cookie with zip only
                                updateType = "partial";
                            }
                        }
                        setZipCookie(zipObject, updateType);
                        try {
                            if (!nationalOption) {
                                // @ts-ignore
                                switch (window.zm_entry) {
                                    case "bypass":
                                        zipUtag("false", "bypass", window.location.href);
                                        break;
                                    case "nat_zip":
                                        zipUtag("false", "zip_nat", window.location.href);
                                        break;
                                    default:
                                        zipUtag("true", "modal", window.location.href);
                                        break;
                                }
                            }
                        } catch (e) {
                            ////utag not ready
                        }
                    } else {
                        zipUtag("true", "national", zipGateURL);
                        window.location.href = zipGateURL;
                    }
                } catch (error) {
                    if (!nationalOption) {
                        zipUtag("true", "national", zipGateURL);
                        window.location.href = zipGateURL;
                    }
                }
            } else {
                if (!nationalOption) {
                    zipUtag("true", "national", zipGateURL);
                    window.location.href = zipGateURL;
                }
            }
        }).catch(() => {
        if (!nationalOption) {
            ////API down, send to national for routing
            zipUtag("true", "national", zipGateURL);
            window.location.href = zipGateURL;
        }
    });
}

/**
 *
 *  Update cookie once user has been verified to be a member of ACE.
 *
 * @param zipObj - zip code object consisting of zip code, club state, and club code
 * @param updateType - determine if full zip cookie was updated or only the zip code
 */
export function setZipCookie(zipObj, updateType):void {
    const cookieVal = zipObj.zipCode + "|AAA|" + zipObj.clubCode + "|" + deviceType;
    const expDate = new Date();
    ///30 day CA cookie life
    const cookieLength = (zipObj.clubState === "CA") ? 30 : 365;
    expDate.setDate(expDate.getDate() + cookieLength);
    ///write zip code cookie per national format
    document.cookie = "zipcode=" + cookieVal + ";expires=" + expDate.toUTCString() + "; domain=.aaa.com; secure; path=/";
    ////set region cookie
    document.cookie = "aaa-region=" + zipObj.clubName + ";expires=" + expDate.toUTCString() + "; domain=.aaa.com; secure; path=/";
    ///set user state
    document.cookie = "aaa-state=" + zipObj.fullState + ";expires=" + expDate.toUTCString() + "; domain=.aaa.com; secure; path=/";
    ///shared club data ex: CLUB|ZIPCODE|CLUBCODE - calif|92626|004
    document.cookie = "ace-zipdata=" + zipObj.clubState + "|" + zipObj.zipCode + "|" + zipObj.clubCode + " ;expires=" + expDate.toUTCString() + "; domain=.aaa.com; secure; path=/";

    ////determine if full cookie was updated or only the zip code
    if (updateType === "full") {
        const zipEvent = new CustomEvent("zipRouteUpdate", {detail: {club: zipObj.clubName, clubCode: zipObj.clubCode, zipCode: zipObj.zipCode}});
        document.dispatchEvent(zipEvent);
    } else {
        const zipCodeUpdatedEvent = new CustomEvent("zipCodeUpdatedEvent", {detail: {clubCode: zipObj.clubCode, zipCode: zipObj.zipCode}});
        document.dispatchEvent(zipCodeUpdatedEvent);
    }

}

/**
 *  Used for analytics
 *
 * @param show - Was router shown
 * @param entry - Entry point
 * @param destination - Where user is being routed to.
 */
function zipUtag(show, entry, destination):void {
    // @ts-ignore
    window.zm_show = show;
    // @ts-ignore
    window.zm_entry = entry;
    // @ts-ignore
    window.zm_destination = destination;

    document.dispatchEvent(new CustomEvent("zip_utag", {
        detail: {
            // @ts-ignore
            show: window.zm_show,
            // @ts-ignore
            entry: window.zm_entry,
            destination: destination
        }
    }));
}

/**
 *   Validate if current request is a user or crawler
 *
 *  * @returns {boolean} - if is a web crawler
 */
function crawlerCheck():boolean {
    return ZIP_CRAWLERS.indexOf(navigator.userAgent) !== -1;
}

/**
 *
 * Reset cleared flag to launch modal
 *
 */
function clearZipCookie():void {
    const expDate = new Date();
    expDate.setDate(expDate.getDate() + 365);
    document.cookie = "zipcode=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.aaa.com; secure; path=/";
    document.cookie = "aaa-region=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.aaa.com; secure; path=/";
    document.cookie = "aaa-state=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.aaa.com; secure; path=/";
    document.cookie = "ace-zipdata=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.aaa.com; secure; path=/";
    launchZipModal();
}

/**
 *   Is user running Android or iOS
 *
 *  @returns {string} - mobile OS name
 */
export function getMobileOS():string {
    if (ON_MOBILE) {
        return USER_AGENT.match(/iPhone|iPod/i) !== null || (USER_AGENT.match(/iPad/) !== null) ? "iOS" : "Android";
    } else {
        return "";
    }
}

/**
 *   Get value for a requested cookie
 *
 *  @param cookieName - Requested cookie name
 *  @returns {Object | string} - club the ad is targeting
 */
export function cookieMonster(cookieName):string {
    let cookieResult = null;
    let tempCookie = undefined;
    let cookies = document.cookie.split(";");
    const cookieRegex = new RegExp(cookieName + "=", "g");
    cookies.forEach(function (cookieVal) {
        if (cookieVal.match(cookieRegex)) {
            tempCookie = decodeURI(cookieVal);
        }
    });
    /////aceuser has odd format, need to handle it differently
    if (tempCookie !== undefined && tempCookie !== null && tempCookie.indexOf("=") > 0) {
        cookieResult = (cookieName === "aceuser") ? tempCookie.split("&")[0].split("=")[2] : tempCookie.split('=')[1];
    }
    return cookieResult;
}

/**
 *   Iterate through query string for targeted club in ad
 *
 *  @param pmedStr - Underscore delimited query string for pmed
 *  @returns {string} - club the ad is targeting
 */
export function pmedValidation(pmedStr) {
    let pmedData = pmedStr.split("_");
    let stateClub = undefined;
    if (pmedData.length > 0) {
        for (let i = 0; i < pmedData.length; i++) {
            if (STATES_MAP.get(pmedData[i].toLowerCase()) !== undefined) {
                stateClub = STATES_MAP.get(pmedData[i].toLowerCase());
            }
        }
    }
    return stateClub;
}

if (document.readyState === "interactive" || document.readyState === "complete") {
    initZipRouter();
} else {
    document.addEventListener("readystatechange", () => {
        if (document.readyState === "interactive" || document.readyState === "complete") {
            initZipRouter();
        }
    })
}

export function redirectArea():string {
    let areaPath:string = "";
    const pathNames: string[] = window.location.pathname.split("/");
    if(pathNames.length > 2){
        areaPath = pathNames[1];
    }else if(pathNames.length === 2){
        areaPath = pathNames[1].slice(0, pathNames[1].lastIndexOf("."));
    }

    return areaPath;
}